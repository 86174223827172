const LandingLayout = ({ children, promo, details, visibility, id, background }) => {

    return (
        <>
            <div className={`lgx:w-full relative ${!background ?  "bg-lightskyblue" : background} ${(promo && visibility && details?.show_banner) ? (id == 'main' ? 'mt-12' : 'mt-32') : 'mt-12'}  lmd:mt-16 mmd:mt-0 flex flex-col p-10 items-center justify-center text-left text-[45px] text-darkslateblue-200 llg:flex-row mmd:h-auto mmd:flex-row mmd:gap-[10px] mmd:items-center mmd:justify-between mmd:px-8 mmd:pb-0 mmd:box-border  ssm:gap-[32px] ssm:pl-3 ssm:pr-3 ssm:box-border ssm:py-10`} id={`${promo ? 'asesoria_fiscal_main_promo' : 'asesoria_fiscal_main'}`}>
                <div className="w-full flex flex-row items-start justify-start gap-[32px] max-w-[1024px] llg:flex-row mmd:w-full mmd:flex-col mmd:gap-[42px] mmd:items-center mmd:justify-start mmd:box-border ssm:w-full ssm:flex-col ssm:gap-[16px] ssm:items-center ssm:justify-start" id={id}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default LandingLayout;