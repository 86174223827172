import { useEffect, useState } from "react";
import dynamic from 'next/dynamic'

//Dynamic Imports
const FixatMediaSwiper = dynamic(() => import('./FixatMediaSwiper'), {
    ssr: false,
})
const FixatMediaDiv = dynamic(() => import('./FixatMediaDiv'), {
    ssr: false,
})

const FixatMediaShow = ({media}) => {
    const mediaInfo = JSON.parse(media);
    const [classN, setClassN] = useState("");
    const [classD, setClassD] = useState("");
    useEffect(() => {
        if(mediaInfo.length > 3){
            setClassN("block")
            setClassD("hidden")
        }else{
            setClassN("hidden")
            setClassD("block")
        }
    }, [mediaInfo.length])
    return (
        <div className="pt-0 lg:pt-20 pb-4 md:pt-28 md:pb-14 bg-white">
            <FixatMediaSwiper  media={mediaInfo} className={classN}/>
            <FixatMediaDiv mediaInfo={mediaInfo} className={classD}/>
        </div>
    );
}

export default FixatMediaShow;