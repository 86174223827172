import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

function Dropdown({ label, needed, name, id, register, isValid, options, errors, ...rest }) {
    return (
        <div className='flex flex-col items-start w-full'>
            {label && (
                <label htmlFor={id} className="text-left text-[14px] text-[#165187]">
                    {label}
                    {needed && <span className='text-red-400'> *</span>}
                </label>
            )}
            <div className="w-full relative rounded-md shadow-sm">

                <select
                    {...register(name, {
                        required: needed && "Selecciona una opción",
                    })}
                    {...rest}
                    name={name}
                    id={id}
                    className={`${isValid ? 'border-green-success' : 'border-gray-300'} mt-2 block w-full rounded-md py-2 pl-3 pr-10 text-gray-900  focus:ring-2 focus:ring-blue-sky focus:border-blue-sky sm:text-sm sm:leading-6`}
                    defaultValue={""}
                >
                    <option value="" disabled hidden >Selecciona una opción</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <Transition
                    show={errors[name] != null}
                    enter="transition-all ease-in"
                    enterFrom="max-h-0 opacity-0"
                    enterTo="max-h-[3rem] opacity-100"
                    leave="transition-all ease-out"
                    leaveFrom="max-h-[3rem] opacity-100"
                    leaveTo="max-h-0 opacity-0">
                    <span className='text-sm text-red'>
                        {errors[name] && <span>{"Selecciona una opción, por favor"}</span>}
                    </span>
                </Transition>
            </div>
        </div>
    )
}

Dropdown.propTypes = {
    label: PropTypes.string,
    needed: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.array
}

Dropdown.defaultProps = {
    needed: false,
    options: ["Selecciona una opción"]
}

export default Dropdown;